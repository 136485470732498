:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #cccccc;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --purple-gradient: linear-gradient(to right, #4c316d, #8f5e95);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.rounded {
  border-radius: 50%;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: #e36661;
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  background: linear-gradient(to right, #4c316d, #8f5e95);
  color: whitesmoke;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 3px;
}

.btn:hover {
  opacity: 0.8;
}

.btn-info {
  display: inline-block;
  color: whitesmoke;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 3px;
}

.btn-light {
  background: var(--light-color);
  color: #333;
}

.btn-info:hover {
  opacity: 0.8;
}

.bg-gradient {
  background-image: linear-gradient(to right, #004a98, #0c84c7);
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.alert-success {
  background: var(--success-color);
  color: #fff;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type="text"],
.form input[type="number"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type="submit"],
button {
  font: inherit;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.5rem;
  z-index: 1;
  width: 100%;
  top: 0;

  background-color: white;
}

.navbar div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: gray;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: #4c316d;
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

#desktopNav {
  background-color: #eeeded;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#desktopNav ul {
  display: flex;
  justify-content: flex-end;
}

#desktopNav ul li {
  padding: 0.45rem;
  margin: 0 0.25rem;
  color: black;
}

#desktopNav ul li:hover {
  color: #4c316d;
  cursor: pointer;
}

#movilNav {
  display: none;
}

#navSocialLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navBarLink {
  color: black;
  text-decoration: none;
}

.navBarLink:hover {
  color: #4c316d;
}

.tramites {
  display: flex;
  flex-direction: column;
}

/* Landing Page */
.landing {
  height: 100vh;
}

.landing-movil {
  display: none;
}

/* Banner */
.banner {
  position: relative;
  height: 35vh;
  background: var(--purple-gradient);

  display: flex;
  align-items: center;
}

.banner-blue {
  height: 25vh;
  background-image: linear-gradient(to right, #004a98, #0c84c7);
}

.banner-red {
  height: 25vh;
  background-image: linear-gradient(to right, #e1062d, #e74b65);
}

.banner-red section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

.banner-talleres {
  height: 44vh;
  background: url("./img/titulo-01.jpg") center center/cover;
}

.banner-vial {
  height: 44vh;
  background: url("./img/SEG-01.jpg") center center/cover;
}

.banner-convocatorias {
  height: 44vh;
  background: url("./img/banner-violeta.png") center center/cover;
}

/* Iconsbar */
.iconsbar {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.iconsbar section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in;

  margin-top: 15px;
  margin-bottom: 15px;
}

.iconsbar section:hover {
  cursor: pointer;
  opacity: 0.7;
}

/* News */
.news-container {
  max-width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  padding-top: 5rem;
}

.news-mobile {
  display: none;
}

.news-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.news-img {
  width: 400px;
  height: auto;
  margin-right: 20px;
}

/* Talleres */
.taller-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 5rem;
}

.taller-container div {
  flex-grow: 1;
}

.tallerItem {
  width: 60%;
  margin: auto;
  margin-bottom: 3rem;
}

#imgNoticiaIndividual {
  width: 95%;
  height: auto;
}

/* Table */
table {
  width: 90%;
  margin-bottom: 30px;
}

table tr {
  margin: 10px;
  padding: 10px;
}

table tr td {
  background: whitesmoke;
  margin: 10px;
  padding: 10px;
  border-radius: 3px;
}

/* Footer */
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeded;
}

.footerImage {
  height: 60px;
  width: auto;
}

.icon {
  width: 85px;
  height: 85px;
}

/* Links de Interes */
.links {
  display: flex;
  flex-direction: column;
  padding: 7px;
}

/* Texto sobre Imagen */
.textOverImage {
  position: relative;
  width: 15rem;
  height: 15rem;
  background-size: 190%;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: 0.5s;
}

.textOverImage:hover {
  background-size: 200%;
}

.divOverImage {
  height: 100%;
  padding: 1rem;
  opacity: 0;
  color: #fff;

  transition: 0.5s;
}

.divOverImage:hover {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

/* Licencia de Conducir */
#licenciaConducirDiv {
  width: 90%;
  margin: 0 4rem 3rem 0;
}

/* Derecho de Registro e Inspeccion */
#derechoRegistroInspeccion {
  font-size: 40px;
}

.linksImportantes {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.linkImportante {
  text-decoration: none;
  color: white;
}

.linkImportante:hover {
  text-decoration: underline;
}

/* Mobile Styles */
@media (max-width: 700px) {
  /* Landing */
  .landing {
    display: none;
  }

  .landing-movil {
    display: flex;
    position: relative;
    height: 85vh;
  }

  /* Navbar */
  .navbar {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  #desktopNav {
    display: none;
  }

  #movilNav {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  #movilNav:hover {
    cursor: pointer;
  }

  #navSocialLinks {
    display: none;
  }

  .movilList ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0.5rem;
  }

  .movilList ul li {
    margin-top: 1rem;
    margin-right: 0;
  }

  .movilList ul li span {
    margin-right: 0;
    padding-right: 0;
  }

  .tramites {
    margin: 0;
    padding: 0;
    align-items: flex-end;
  }

  /* Iconsbar */
  .iconsbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .iconsbar section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  /* News */
  .news-desktop {
    display: none;
  }

  .news-container {
    max-width: 100%;
    margin: 0;
  }

  .news-mobile {
    display: block;
  }

  .news-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .news-img {
    width: 100%;
    height: auto;
    margin-right: 0;
  }

  #imgNoticiaIndividual {
    width: 100%;
    height: auto;
  }

  .NoticiaIndividual {
    padding-left: 7px;
  }

  /* Taller */
  .tallerItem {
    width: 90%;
  }

  /* Footer */
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer a {
    margin-top: 15px;
  }

  .footer p {
    font-size: 12px;
  }

  .footerImage {
    height: 50px;
    width: auto;
  }

  #footerTextSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* More News */
  .moreNewsDesktop {
    display: none;
  }

  /* Events Arrows */
  .eventArrow {
    display: none;
  }

  /* Tables */
  table {
    width: 100%;
  }

  .hide-sm {
    display: none;
  }

  /* Banner Red */
  .banner-talleres {
    height: 20vh;
    background: url("./img/banner-red-phone.jpg") center center/cover;
  }

  .banner-red section {
    text-align: center;
  }

  /* Banner Vial */
  .banner-vial {
    height: 25vh;
    background: url("./img/banner-yellow-phone.jpg") center center/cover;
  }

  /* Banner Convocatorias */
  .banner-convocatorias {
    height: 25vh;
    background: url("./img/banner-violeta-movil.png") center center/cover;
  }

  /* Licencia de Conducir */
  #licenciaConducirDiv {
    margin: 0rem 1rem 2rem 1rem;
  }

  /* Derecho de Registro e Inspeccion */
  #derechoRegistroInspeccion {
    font-size: 35px;
  }

  .linksImportantes {
    flex-direction: column;
  }
}

/* Tablets Styles */
@media (max-width: 800px) {
  /* News */
  .news-desktop {
    display: none;
  }

  .news-mobile {
    display: block;
  }

  .news-container {
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
  }

  .moreNewsDesktop {
    display: none;
  }

  .eventArrow {
    display: none;
  }
}
